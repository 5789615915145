<template>
	<div>
		<div class="d-flex justify-content-between align-items-center">
			<h2 class="header-common"> {{ $t('planes.listHeader') }}</h2>
			<button class="btn btn-outline-primary position-relative" v-if="planeChangesCount > 0" @click="goToChanges()">
				<span class="badge rounded-pill bg-danger text-white">
					{{planeChangesCount}}
				</span>
				{{ $t('planes.changes.title') }}
				<font-awesome-icon :icon="['fas', 'chevron-right']" />
			</button>
		</div>

		<ServerError v-if="error" @retry="load()" />

		<div class="plane-selector" v-if="!error">
			<div class="add-card" @click="createNewPlane">
				<div class="icon-box">
					<font-awesome-icon icon="plus" />
				</div>
				<div class="name">{{ $t('planes.addPlane') }}</div>
			</div>

			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />
			<Skeleton v-if="loading" :count="1" width="100%"  class="skeletons" height="100%" />


			<template v-if="!loading">
				<plane-card :userPlane="up" v-for="up in userPlanes" v-bind:key="up.plane.id" @click="selectPlane(up)">
					<button class="btn btn-warning btn-sm" @click.stop="selectPlane(up)">
						<font-awesome-icon icon="pen" class="m-0" />
					</button>
					<button class="btn btn-dark btn-sm" @click.stop="copyPlane(up)">
						<font-awesome-icon icon="copy" class="m-0" />
					</button>
					<share-button type="plane" :shared-id="String(up.plane.id)" size="sm"  />
					<button class="btn btn-danger btn-sm" @click.stop="deletePlane(up)">
						<font-awesome-icon icon="trash" class="m-0" />
					</button>
				</plane-card>
			</template>
		</div>
	</div>
</template>

<script>
import { Fade } from "@egjs/flicking-plugins";
import PlaneCard from "@/components/plane/PlaneCard.vue";
import AddPlaneModal from "@/components/plane/AddPlaneModal.vue";
import ShareButton from "@/components/sharing/ShareButton.vue";
import { Skeleton } from "vue-loading-skeleton";
import ServerError from "@/components/ServerError.vue";

export default {
	name: 'PlanesView',
	components: { ServerError, ShareButton, PlaneCard, Skeleton },
	computed: {
		cancelBtnTitle() {
			return this.$t('planes.cancelButton')
		}
	},
	data() {
		return {
			plugins: [new Fade()],
			/** @type {UserPlane[]} */
			userPlanes: [],
			planeChangesCount: 0,
			loading: false,
			error: false,
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		goToChanges() {
			this.$router.push({ name: 'planeChanges' });
		},
		/** @returns {Promise<boolean>} */
		async loadChangesCount() {
			const changes = await this.$rest.loadAllPlaneChanges();
			if(!changes) return false;
			this.planeChangesCount = changes.length;
			return true;
		},
		/** @returns {Promise<boolean>} */
		async loadData() {
			const ups = await this.$rest.loadMyUserPlanes();
			if(!ups) return false;
			this.userPlanes = [...(ups || [])];
			return true;
		},
		async copyPlane(userPlane) {
			const plane = userPlane.plane;
			window.newEvent("NEW_PLANE", {
				fromId: plane ? plane.id : null
			})
			await this.$router.push({
				name: 'planeCreate',
				query: {
					from: plane ? plane.id : null
				}
			})
		},
		async deletePlane(userPlane) {
			const plane = userPlane.plane;
			if (await this.$confirm(this.$t("planes.deleteConfirm"))) {
				await this.$rest.detachFromPlane(plane.id);
				await this.$router.replace(`/planes`);
			}
		},
		selectPlane(userPlane) {
			this.$router.push({ name: 'plane', params: { id: `${userPlane.plane.id}` } })
		},
		async createNewPlane() {
			// this.$modal.show(AddPlaneModal, {}, {
			// 	height: 'auto',
			// 	clickToClose: true,
			// 	adaptive: true,
			// }, {
			// 	'before-close': () => {
			// 		this.loadData()
			// 	}
			// });
			window.newEvent("NEW_PLANE", {
				fromId: null
			})
			await this.$router.push({
				name: 'planeCreate',
				query: {
					from: null,
				}
			})
		},
		async load() {
			this.loading = true;
			this.error = false;
			const results = await Promise.all([
				await this.loadData(),
				await this.loadChangesCount(),
			]);
			this.loading = false;

			if(!results.every(r => r)) {
				this.error = true;
			}
		}
	},
	async beforeMount() {
		await this.load();
	},
}

</script>

<style scoped>
.header-common{
	padding: 0;
}
.add-card {
	width: 100%;
	height: 100%;
	background-color: #0086FF;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	transition: all 0.1s ease-in-out;
	padding: 20px;
	border: 1px solid transparent;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
	gap: 1rem;
	min-height: 185px;
}

.add-card:hover {
	scale: 1.03;
}

.add-card .icon-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1px 0;
}

.add-card .icon-box svg {
	width: 32px;
	height: 32px;
}

.add-card .name {
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	white-space: pre-wrap;
}

.plane-selector {
	margin-top: 2rem;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1rem;
}

@media (max-width: 1000px) {
	.plane-selector {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width: 800px) {
	.add-card {
		min-height: 170px;
	}
	.plane-selector {
		grid-template-columns: repeat(3, 1fr);
	}
	.add-card .icon-box svg {
		width: 24px;
	}

	.add-card .name {
		font-size: 0.8rem;
	}
}

@media (max-width: 500px) {
	.plane-selector {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 380px) {
	.plane-selector {
		grid-template-columns: repeat(1, 1fr);
	}
}


.skeletons {
	margin: 0;
	padding: 0;
	min-height: 185px;
}


</style>
<style>
.pu-skeleton {
	line-height: unset !important;
}
</style>
